var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar",class:_vm.sidebarTheme},[_c('ul',{staticClass:"nav mt-2 mb-4 d-flex"},[(_vm.myOrganizations.length > 1)?_c('li',{staticClass:"nav-item nav-organization-selector"},[_c('div',{staticClass:"mb-3"},[_c('b-form-select',{attrs:{"options":_vm.organizationsOptions,"disabled":_vm.disableSelect,"size":"sm"},on:{"change":_vm.switchOrganization},model:{value:(_vm.selectedOrganization),callback:function ($$v) {_vm.selectedOrganization=$$v},expression:"selectedOrganization"}})],1)]):_vm._e(),(_vm.showBranch)?_c('li',{staticClass:"nav-item nav-organization-selector"},[_c('div',{staticClass:"mb-3"},[_c('b-form-select',{attrs:{"options":_vm.branchOptions,"disabled":_vm.disableSelect,"size":"sm"},on:{"change":_vm.switchBranch},model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})],1)]):_vm._e(),_vm._l((_vm.sidebarMap),function(i){return [(
          i.items.length > 0 &&
          (i.checkPermissionAny
            ? _vm.checkPermissionAny(i.checkPermissionAny)
            : i.checkPermission
            ? _vm.checkPermission(i.checkPermission)
            : true)
        )?_c('li',{key:i.key,staticClass:"nav-item",style:({ order: _vm.sidebarOrder(i.key) || i.order })},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("" + (i.key))),expression:"`${i.key}`"}],staticClass:"nav-link"},[_c('icon',{attrs:{"src":i.icon}}),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(i.text))]),_c('i',{staticClass:"menu-arrow"})],1),_c('b-collapse',{attrs:{"id":i.key,"visible":_vm.subMenuVisible(i)}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_vm._l((i.items),function(j,jdx){return [(
                  j.checkPermissionAny
                    ? _vm.checkPermissionAny(j.checkPermissionAny)
                    : j.checkPermission
                    ? _vm.checkPermission(j.checkPermission)
                    : true
                )?_c('li',{key:jdx,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.subMenuActive(j) && 'active',attrs:{"to":j.to}},[_vm._v(_vm._s(j.text))])],1):_vm._e()]})],2)])],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }